/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

/* Hero Container */
.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  animation: fadeSlide 20s infinite;
}

@keyframes fadeSlide {
  0% {
    background-image: url("../../images/background/img0.jpg");
  }
  25% {
    background-image: url("../../images/background/img01.png");
  }
  50% {
    background-image: url("../../images/background/img04.jpg");
  }
  75% {
    background-image: url("../../images/background/img06.jpg");
  }
  100% {
    background-image: url("../../images/background/img0.jpg");
  }
}

.hero-container > h1 {
  text-align: center;
  margin-top: 0;
  color: #ffffff;
  font-size: 6.5rem;
  animation: slideInDown 1s ease-in-out;
}

.hero-container > p {
  text-align: center;
  margin-top: 8px;
  color: #ffffff;
  font-size: 2.5rem;
  margin-left: 5%;
  margin-right: 5%;
  animation: slideInUp 1s ease-in-out;
  background-color: #fd4511;
  padding: 10px;
  border-radius: 5px;
}

/* Hero Button Group */
.hero-btn-group {
  margin-top: 50px;
}

.hero-btn-group .btn {
  margin: 6px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  animation: bounceIn 1s ease-in-out;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.hero-btn-group .btn:hover {
  background-color: #ddd;
  transform: scale(1.1);
}

.hero-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

/* Mission Section */
.mission {
  margin: 0;
  padding: 1rem;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  animation: fadeIn 2s ease-in-out;
  margin-top: 0%;
}

.mission > h1 {
  text-align: center;
  color: #fff;
  margin: 20px auto 0;
  font-size: 2.5rem;
  animation: slideInDown 1s ease-in-out;
  background-color: #fd4511;
  width: 60%;
}

.mission > p {
  text-align: justify;
  max-width: 1200px;
  width: 100%;
  margin: 20px auto 0;
  line-height: 2;
  font-size: 1.25rem;
  color: #ffffff;
}

/* Activities Button Section */
.activities-button {
  margin: 2px 0;
  padding: 4rem;
  background: #000;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease-in-out;
}

.activities-button > h1 {
  text-align: center;
  margin-bottom: 1px;
  color: #ffffff;
  font-size: 2.5rem;
  animation: slideInDown 1s ease-in-out;
}

.activities-button .btn {
  margin: 6px;
  padding: 12px 24px;
  font-size: 1rem;
  color: #fff;
  background-color: #ff4500;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  animation: bounceIn 1s ease-in-out;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.activities-button .btn:hover {
  background-color: #ff6347;
  transform: scale(1.1);
}

/* Latest Project Section */
.latestProject {
  margin: 2px 0;
  padding: 4rem;
  background: #000;
  font-size: 1.25rem;
  animation: fadeIn 2s ease-in-out;
}

.latestProject > h1 {
  text-align: center;
  color: #ffffff;
  animation: slideInDown 1s ease-in-out;
  background-color: #fd4511;
  width: 30%;
  margin-left: 35%;
  align-items: center;
  margin-bottom: 2%;
}

/* Player Wrapper */
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* player ratio: 100 / (1280 / 720) */
  margin: 25px auto 0;
  width: 90%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* Responsive Styles */
@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 2.5rem;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 2rem;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 1.25rem;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .mission,
  .activities-button,
  .latestProject {
    padding: 2rem 1rem;
  }

  .mission > h1,
  .activities-button > h1,
  .latestProject > h1 {
    width: 100%;
    margin-left: 0;
    font-size: 2rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-30px);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(-2px);
  }
}
