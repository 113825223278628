/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

.foundation, .ourTeam, .contributors {
  margin: 25px 0;
  padding: 4rem 2rem;
  background: #000;
  color: white;
}

.foundation > h1, .ourTeam > h1, .contributorsHeader > h1 {
  text-align: center;
  background-color: #fd4511;
  font-size: 2.5rem;
  margin: 0 auto 1rem auto;
  padding: 1rem 0;
  color: white;
}

.foundation > p, .contributorsHeader > select, .contributorNames > p {
  text-align: justify;
  max-width: 1120px;
  width: 90%;
  margin: 25px auto 0 auto;
  line-height: 1.5;
  font-size: 1.2rem;
}

.ourTeam {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.teamPhotos {
  max-width: 2000px;
  width: 75vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  margin: 25px;
  background-color: #fd4511;
}

.card-container {
  margin: auto;
}

.card .profile-img {
  max-width: 240px;
  max-height: 240px;
  flex: 1;
  border-radius: 5px;
  box-shadow: 3px 3px 10px #aaaaaa;
  margin: auto;
}

.card-bottom {
  display: flex;
  width: 240px;
  min-height: 80px;
  text-align: center;
}

.info {
  flex: 1 auto;
}

.card h3 {
  margin: 10px 0px 0px 5px;
  color: white;
}

.card p {
  margin: 2px 5px;
}

.contributors {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contributorsHeader > select {
  margin: 20px auto 0 auto;
  padding: 5px 0px;
  width: 100%;
}

.contributorNames {
  max-width: 1120px;
  width: 90%;
  margin: 25px auto 0 auto;
  column-count: 4;
  text-align-last: left;
  color: white;
}

.contributorNames > p {
  text-align: center;
  line-height: 1.5;
}

/* Define a keyframe animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Delay each paragraph animation progressively */
.contributorNames > p:nth-child(odd) {
  animation-delay: 0.1s; /* Adjust delay as needed */
}

.contributorNames > p:nth-child(even) {
  animation-delay: 0.2s; /* Adjust delay as needed */
}

@media screen and (max-width: 1100px) {
  .contributorNames {
    column-count: 3;
  }
}

@media screen and (max-width: 900px) {
  .contributorNames {
    column-count: 2;
  }
}

@media screen and (max-width: 700px) {
  .contributorNames {
    column-count: 1;
  }
}

@media screen and (max-width: 768px) {
  .foundation,
  .ourTeam,
  .contributors {
    padding: 2rem 1rem;
  }
}
