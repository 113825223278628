/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

.whatWeDo, .makeAnImpact, .donateSection, .about-us {
  margin: 25px 0;
  padding: 4rem 2rem;
  background: #000;
  color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.whatWeDo > h1, .makeAnImpact > h1, .about-us > h2 {
  text-align: center;
  background-color: #fd4511;
  font-size: 2.5rem;
  margin: 0 auto 1rem auto;
  padding: 1rem 0;
  color: white;
  border-radius: 8px;
  animation: slideIn 1s ease-in-out;
}

.whatWeDo > p, .donateSection > p, .about-us > p, section > p {
  text-align: justify;
  max-width: 800px;
  width: 90%;
  margin: 25px auto;
  line-height: 1.5;
  font-size: 1.2rem;
  animation: fadeIn 1s ease-in-out;
}

.donateSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
}


@media screen and (max-width: 768px) {
  .whatWeDo, .makeAnImpact, .donateSection, .about-us {
    padding: 2rem 1rem;
  }

  .whatWeDo > h1, .makeAnImpact > h1, .about-us > h2 {
    font-size: 2rem;
    margin-left: 0;
  }

  .whatWeDo > p, .donateSection > p, .about-us > p, section > p {
    font-size: 1rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
