.footer-container {
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 10px 0;
  
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100%;
  max-width: 1000px;
  margin: 40px auto 20px auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.website-rights {
  color: #fff;
  margin-bottom: 20px;
}
