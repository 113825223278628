/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
  padding-top: 80px; /* Match this to the navbar's height */
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

/* Navbar Styles */
.navbar {
  background: linear-gradient(100deg, rgba(253, 69, 17, 1) 50%, rgb(26, 23, 23) 100%);
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed; /* Change this to fixed */
  top: 0;
  z-index: 9999; /* Ensure it's above other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s; /* Optional smooth transition */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1500px;
  padding: 0 24px;
}

.navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 50px;
  width: auto;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: 80px;
  position: relative;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  display: none;
  position: absolute;
  background: #ffffff;
  top: 80px;
  list-style: none;
  width: 300px;
  text-align: left;
  padding: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.dropdown-menu li {
  height: 50px;
}

.dropdown-link {
  color: #fd4511;
  display: block;
  padding: 1rem;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
}

.dropdown-link:hover {
  background-color: #f5f5f5;
  color: #000;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

/* Responsive Styles */
@media screen and (max-width: 1000px) {
  .navbar-container {
    padding: 0 16px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
    background: #242222;
    z-index: 1;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
