/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

/* Section Styles */
.whatWeDo, .aboutsec, .donateSection, .video-section, .carousel-section {
  margin: 2px 0 25px 0;
  padding: 4rem 2rem;
  background: #000000;
  color: white;
}

.whatWeDo > h1, .aboutsec > h1, .video-title, .carousel-title {
  text-align: center;
  background-color: #fd4511;
  font-size: 2.5rem;
  width: 100%;
  margin: 0 auto 1rem auto;
  padding: 1rem 0;
}

.whatWeDo > p, .donateSection > p {
  text-align: justify;
  max-width: 1120px;
  width: 90%;
  margin: 25px auto 0 auto;
  line-height: 1.5;
  font-size: 1.2rem;
}

.donateSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container video {
  margin: 20px 0;
}

.photo-section {
  text-align: center;
  margin: 20px 0;
  width: 100%;
}

.photo {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.photo-title, .video-title, .carousel-title {
  font-size: 2rem;
  margin-top: 10px;
  color: #ddd;
  background-color: #fd4511;
  padding: 0.5rem 0;
}

.photo-text {
  font-size: 1rem;
  margin-top: 5px;
  color: #ddd;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .whatWeDo, .aboutsec, .donateSection, .video-section, .carousel-section {
    padding: 2rem 1rem;
  }

  .whatWeDo > h1, .aboutsec > h1, .video-title, .carousel-title {
    font-size: 1.5rem;
  }

  .whatWeDo > p, .donateSection > p {
    font-size: 1rem;
  }

  .photo-title, .video-title, .carousel-title {
    font-size: 1.5rem;
  }

  .photo-text {
    font-size: 0.9rem;
  }
}

/* Additional Styles for Desktop View */
@media screen and (min-width: 769px) {
  .whatWeDo > h1, .aboutsec > h1, .video-title, .carousel-title {
    font-size: 3rem;
  }

  .whatWeDo > p, .donateSection > p {
    font-size: 1.5rem;
  }

  .photo-title, .video-title, .carousel-title {
    font-size: 2.5rem;
  }

  .photo-text {
    font-size: 1.2rem;
  }
}
