/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

.connectWithUs {
  margin: 25px 0;
  padding: 2rem;
  background: #000000;
}

.connectWithUs > h1 {
  text-align: center;
  color: white;
  background-color: #ff6347;
  font-size: 3rem;
  padding: 1rem;
  margin-bottom: 2rem;
}

.socialSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.socialIcons {
  flex: 1 1 20%;
  margin: 10px;
  text-align: center;
}

.socialIcons > p {
  margin-top: 10px;
  color: #ff6347;
}

.socialIcons > a {
  text-decoration: none;
  color: #ffffff;
}

.socialIcons > i {
  font-size: 2em;
}

@media screen and (max-width: 960px) {
  .socialIcons {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 600px) {
  .socialIcons {
    flex-basis: 100%;
  }
}
