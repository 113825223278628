* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

hr {
  border: 0;
  height: 1px;
  background: #242424;
  width: 45%;
  margin: auto;
  opacity: 0.3;
}

.home,
.about,
.donate,
.news,
.activity,
.projects,
.oha,
.sec,
.contact-us {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  text-align: center;
  color: #fff;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  animation: fadeIn 2s ease-in-out;
}

.about {
  background-image: url("images/background/img01.png");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.donate {
  background-image: url("images/background/img03.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.news {
  background-image: url("images/background/img06.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.activity {
  background-image: url("images/background/img04.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.projects {
  background-image: url("images/background/img04.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.oha {
  background-image: url("images/background/img07.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.sec {
  background-image: url("images/background/img09.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

.contact-us {
  background-image: url("images/background/img05.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 5%;
  background-repeat: no-repeat;
  font-size: 100px;
}

@media screen and (max-width: 768px) {
  .home,
  .about,
  .donate,
  .news,
  .activity,
  .projects,
  .oha,
  .sec,
  .contact-us {
    font-size: 3.5rem;
  }
}
