/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

.projects-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url("../../images/background/img08.jpg") center center/cover no-repeat;

}

.projects-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  margin: 25px 0;
}

.projects-card {
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin: 25px 30px;
  padding: 20px;
  background: rgba(253,69,17,255);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: justify;
}

.projects-card-description {
  margin: 12.5px 0px;
  color: white;
}

.gallery {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8); /* Darker background for better focus */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.gallery-modal {
  max-width: 80vw; /* Slightly increased width for better view */
  max-height: 80vh; /* Slightly increased height for better view */
  width: auto;
  height: auto;
  background-color: #111; /* Slightly lighter black for contrast */
  color: white;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
  border-radius: 10px; /* Rounded corners for a modern look */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  transform: scale(0.9); /* Start with a slight zoom out effect */
}

.gallery.show .gallery-modal {
  transform: scale(1); /* Zoom in effect when showing */
}


.close-button {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 1.5rem;
  color: white;
  z-index: 999;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  padding: 12.5px;
}

.carousel .slide {
  display: flex;
  justify-content: center;
}

.carousel-image {
  width: auto !important;
  height: 75vh !important;
  max-width: 75vw !important;
}

@media screen and (max-width: 768px) {
  .projects-cards-container {
    width: 80%;
  }

  .carousel-image {
    width: 75vw !important;
    height: auto !important;
    max-height: 75vh !important;
  }
}
