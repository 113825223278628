/* Lightbox Styles */
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .lightbox-content {
    background: #222; /* Slightly lighter background for contrast */
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
    color: #fff;
  }
  
  .lightbox-content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #fd4511; /* Accent color for headings */
  }
  
  .lightbox-content p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #fd4511; /* Accent color for hover effect */
  }
  
  /* Prevent scrolling when lightbox is open */
  body.lightbox-open {
    overflow: hidden;
  }