/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #ddd;
}

/* Section Styles */
.whatWeDo, .makeAnImpact, .donateSection {
  margin: 25px 0;
  padding: 4rem 2rem;
  background: #000;
  color: white;
}

.whatWeDo > h1, .makeAnImpact > h1 {
  text-align: center;
  background-color: #fd4511;
  font-size: 2.5rem;
  margin: 0 auto 1rem auto;
  padding: 1rem 0;
  color: white;
}

.whatWeDo > p, .donateSection > p {
  text-align: justify;
  max-width: 1120px;
  width: 90%;
  margin: 25px auto 0 auto;
  line-height: 1.5;
  font-size: 1.2rem;
}

.donateSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Share Button Styles */
.shareButton {
  background-color: #ff5100; /* Matches your theme color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Space from content above */
}

.shareButton:hover {
  background-color: #000000; /* Black background on hover */
}

.shareButton:active {
  background-color: #ff5100; /* Orange color on active */
}

/* Copy Success Message Styles */
.copySuccessMessage {
  color: #ff6600; /* Bright green for visibility */
  background-color: #333; /* Slightly lighter background for contrast */
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

/* Container that holds the videos */
.videoContainer {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  gap: 10px;
}

/* Each video container */
.videoContainer > div {
  flex: 1;
  min-width: 100%; /* Set a minimum width to avoid overly small videos */
}

/* Ensure videos are responsive */
video {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border: 1px solid #ddd; /* Optional: Add border for better visibility */
}

/* Media Queries for smaller devices */
@media screen and (max-width: 768px) {
  .videoContainer {
    flex-direction: column; /* Stack videos vertically on mobile */
    gap: 10px; /* Add space between stacked videos */
  }

  .videoContainer > div {
    min-width: 100%; /* Ensure full width for stacked videos */
  }

  /* Adjust section padding and font size for mobile */
  .whatWeDo, .makeAnImpact, .donateSection {
    padding: 2rem 1rem;
  }

  .whatWeDo > h1, .makeAnImpact > h1 {
    font-size: 1.5rem;
  }

  .whatWeDo > p, .donateSection > p {
    font-size: 1rem;
  }

  /* Improve share button spacing on smaller screens */
  .shareButton {
    width: 100%; /* Full width on mobile */
    font-size: 1rem; /* Slightly smaller font */
  }
}

/* Additional Styles for Desktop View */
@media screen and (min-width: 769px) {
  .whatWeDo > h1, .makeAnImpact > h1 {
    font-size: 3rem;
  }

  .whatWeDo > p, .donateSection > p {
    font-size: 1.5rem;
  }
}
